import React, { useState, useEffect } from 'react';
import { external1, logo } from '../../assets/images';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const Navbar = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const onSubmit = (data) => {
    console.log('Form Data: ', data);
    reset();
  };

  const [showModal, setShowModal] = useState(false);
  const [navbarColor, setNavbarColor] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // Add a scroll event listener to change navbar color on scroll
  useEffect(() => {
    const changeNavbarColor = () => {
      if (window.scrollY >= 80) {
        setNavbarColor(true);
      } else {
        setNavbarColor(false);
      }
    };
    window.addEventListener('scroll', changeNavbarColor);
    return () => {
      window.removeEventListener('scroll', changeNavbarColor);
    };
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row mx-auto header-bg-nav">
          <div className="col-12 px-lg-5">
            <nav
              className={`w-100 navbar navbar-expand-lg navbar-light fixed-top ${navbarColor ? 'navbar-colored' : 'navbar-transparent'
                }`}
            >
              <div className="container p-lg-3">
                <a className="navbar-brand" href="#">
                  <img src={logo} className="img-fluid navbar-logo" alt="logo" />
                </a>
                <button
                  className="navbar-toggler  border-white "
                  type="button"

                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse bg-white w-100" id="navbarSupportedContent">
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      {/* <a className="nav-link active me-2 navbar-link" aria-current="page" href="#">
                        Solutions
                      </a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link active me-2 navbar-link" aria-current="page" href="#">
                        Technology
                      </a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link active me-2 navbar-link" aria-current="page" href="#">
                        Webinars
                      </a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link active me-2 navbar-link" aria-current="page" href="#">
                        Insights
                      </a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link active me-2 navbar-link" aria-current="page" href="#">
                        Careers
                      </a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link active me-2 navbar-link" aria-current="page" href="#">
                        About Us
                      </a> */}
                    </li>
                    <li className="nav-item">
                      {/* <a className="nav-link active me-2 navbar-link" aria-current="page" href="#">
                        Contact Sales
                      </a> */}
                    </li>
                  </ul>
                  <form className="d-flex justify-content-center align-items-center">
                    <button className="btn contact-btn d-none d-lg-block" onClick={handleShow} type="button">
                      Contact Us
                    </button>
                    <button className="btn btn-warning d-lg-none mb-3" onClick={handleShow} type="button">
                      Contact Us
                    </button>
                  </form>
                </div>
              </div>
            </nav>

            {/* Modal for contact form */}
            <Modal show={showModal} onHide={handleClose} centered size='xl'>


              <Modal.Body>
                <div className="container-fluid ">
                  <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-lg-6 col-md-12">
                      <div className="d-flex m-0 modal-heading justify-content-between">
                        <h1>
                          How can we{" "}
                          <span style={{ color: "#E27239" }}>help you?</span>
                        </h1>
                        <button type="button" className="btn-close d-lg-none d-bloc" onClick={handleClose} data-bs-dismiss="modal" aria-label="Close"></button>

                      </div>
                      <p className="fs-6 m-0 d-lg-none" style={{ color: "#2A3040" }}>
                        Within 24 hours, a representative from our company will
                        contact you.
                      </p>
                      <form onSubmit={handleSubmit(onSubmit)} className="mt-lg-5 mt-3">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <input
                              type="text"
                              placeholder="Enter your Full Name"
                              className={`w-100 p-lg-2 p-1 contact-input  rounded ${errors.name ? "is-invalid" : ""
                                }`}
                              style={{ border: "1px solid #A4A4A480" }}
                              {...register("fullName", { required: "FullName is required" })}
                            />
                            {errors.fullName && (
                              <p className="text-danger">{errors.fullName.message}</p>
                            )}
                          </div>

                          <div className="col-md-6 mb-3">
                            <input
                              type="text"
                              placeholder="Enter your Cell Phone"
                              className={`w-100 contact-input p-lg-2 p-1  rounded ${errors.cellPhone ? "is-invalid" : ""
                                }`}
                              style={{ border: "1px solid #A4A4A480" }}
                              {...register("cellPhone", {
                                required: "Cell phone is required",
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: "Please enter a valid cell phone"
                                },
                                minLength: {
                                  value: 10,
                                  message: "Cell phone should be at least 10 digits"
                                }
                              })}
                            />
                            {errors.cellPhone && (
                              <p className="text-danger">{errors.cellPhone.message}</p>
                            )}
                          </div>

                          <div className="col-md-12 mb-3">
                            <input
                              type="email"
                              placeholder="Enter your Email Address"
                              className={`w-100 contact-input p-lg-2 p-1 rounded ${errors.email ? "is-invalid" : ""
                                }`}
                              style={{ border: "1px solid #A4A4A480" }}
                              {...register("email", {
                                required: "Email address is required",
                                pattern: {
                                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                  message: "Please enter a valid email address"
                                }
                              })}
                            />
                            {errors.email && (
                              <p className="text-danger">{errors.email.message}</p>
                            )}
                          </div>

                          <div className="col-md-12 mb-3">
                            <textarea
                              className={`w-100 contact-input p-2   rounded ${errors.message ? "is-invalid" : ""
                                }`}
                              style={{ border: "1px solid #A4A4A480", height: "80px" }}
                              rows="5"
                              placeholder="Notes/Comments:"
                              {...register("message", {
                                required: "Notes/Comments: is required",
                                minLength: {
                                  value: 10,
                                  message: "Message should be at least 10 characters long"
                                }
                              })}
                            ></textarea>
                            {errors.message && (
                              <p className="text-danger">{errors.message.message}</p>
                            )}
                          </div>

                          <div className="col-md-12">
                            <button
                              type="submit"
                              className=" mb-3  d-lg-none px-lg-5 px-2 btn-warnin rounded-4 py-lg-2 btn-warning border-0 "
                              style={{ backgroundColor: "#E27239", borderRadius: '10px' }}
                            >
                              SUBMIT
                            </button>
                            <button
                              type="submit"
                              className=" mb-3 d-none d-lg-block px-lg-3 btn-warnin rounded-4  btn-warning border-0 "
                              style={{ backgroundColor: "#E27239", fontSize: '16px', borderRadius: '10px', width: '110px', height: '35px' }}
                            >
                              SUBMIT
                            </button>
                            <p className="fs-6 d-none d-lg-block" style={{ color: "#2A3040" }}>
                              Within 24 hours, a representative from our company will
                              contact you.
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="col-lg-6 d-none d-lg-block ">
                      <div className="image-container">
                        <img
                          src={external1}
                          alt="Team Working"
                          className="w-100 h-100 diagonal-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>

            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
