import React from 'react'
import { footer_icon1, footer_icon2, footer_logo, logo } from '../../assets/images'

const Footer = () => {
  return (
    <>
      <div className="footer p-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12 col-md-6 p-2 mt-5">
              <div className="div p-0  d-none d-lg-block px-0 text-white">
                <img src={footer_logo} className='footer-logo me-5' alt="" />
                <p className="footer-logo-para text-white text-start mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                <p className='text-muted footer-email text-start mt-4'>Email to <span className="text-white fw-bold">info@ProBillRCM.com</span> </p>
                <h5 className='text-start text-white mt-4'>+1234-234-456</h5>
                <p className='text-start footer-email mt-4 text-white'>2972 Westheimer Rd. Santa <br /> Ana, Illinois 85486 </p>
              </div>
              <div className="div d-flex flex-md-column justify-content-between d-lg-none p-0 px-0 text-white">
                <div className="div">
                  <img src={footer_logo} className='footer-logo' alt="" />
                  <p className="footer-logo-para text-white text-start mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                </div>
                <div className="div mt-4 mt-md-0">
                  <p className='text-muted footer-email m-0 text-md-start text-end  mt-4'>Email to <span className="text-white fw-bold">info@ProBillRCM.com</span> </p>
                  <h5 className=' text-white text-end text-md-start m-0 '>+1234-234-456</h5>
                  <p className='text-end footer-email text-md-start   text-white'>2972 Westheimer Rd. Santa <br /> Ana, Illinois 85486 </p>
                </div>
              </div>
            </div>
            <div className=" col-6 col-md-3 col-lg-2 mt-lg-5 p-2 d-flex justify-content-center">
              {/* <div className="div">
                <h5 className='text-start text-white footer-link-head'>Quick Links</h5>
                <p className="footer-email text-white text-start">Solutions</p>
                <p className="footer-email text-white text-start">Technology</p>
                <p className="footer-email text-white text-start">Webinars</p>
                <p className="footer-email text-white text-start">Insights</p>
                <p className="footer-email text-white text-start">Careers</p>
                <p className="footer-email text-white text-start">About Us</p>
                <p className="footer-email text-white text-start">Contact Sales</p>
              </div> */}
            </div>
            <div className="col-6 col-md-3 col-lg-2 mt-lg-5 p-2 d-flex justify-content-center">
              {/* <div className="div">
                <h5 className='text-start text-white footer-link-head'>Social Media</h5>
                <p className="footer-email text-white text-start">Facebook</p>
                <p className="footer-email text-white text-start">Instagram</p>
                <p className="footer-email text-white text-start">Youtube</p>
                <p className="footer-email text-white text-start">twiter</p>

              </div> */}
            </div>
            <div className="col-12 col-lg-4 mt-lg-5 p-2 d-flex justify-content-center">
              <div className="div">
                <h5 className='text-start text-white footer-link-head'>News Letter</h5>
                <p className="footer-email text-white text-start">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                <div className="d-flex mt-lg-3">
                  <input
                    type="email"
                    className="form-control input px-4"
                    placeholder="Enter your email"
                    aria-label="Email"
                  />
                  <button className="btn input-btn " type="button">
                    Submit
                  </button>
                </div>
                <h5 className='text-start d-none d-lg-block mt-lg-3 text-white'>Follow Us on:</h5>
                <div className="d-flex d-none d-lg-block  justify-content-center media-icon">
                  <img src={footer_icon1} className='footer-icon' alt="" />
                  <img src={footer_icon2} className='footer-icon' alt="" />
                  <img src={footer_icon1} className='footer-icon' alt="" />
                  <img src={footer_icon2} className='footer-icon' alt="" />

                </div>
                <div className="d-flex d-lg-none mt-3  justify-content-center media-icon">
                <h5 className='text-start mt-lg-5 text-white mt-2'>Follow Us on:</h5>

                  <img src={footer_icon1} className='footer-icon' alt="" />
                  <img src={footer_icon2} className='footer-icon' alt="" />
                  <img src={footer_icon1} className='footer-icon' alt="" />
                  <img src={footer_icon2} className='footer-icon' alt="" />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer