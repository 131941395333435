import './App.css';
import Navbar from './componets/header/Navbar';
import Section from './componets/pages/Section1';
import Footer from './componets/footer/Footer';
// import Contact from './componets/pages/Contact';

function App() {
  return (
    <div className="App">
     
     <Navbar/>
     <Section/>
     
     <Footer/>
    </div>
  );
}

export default App;
